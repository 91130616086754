import React, { useState } from "react";
// types
import { MobileMenuContextType } from "src/core/types";

export const MobileMenuContext = React.createContext<MobileMenuContextType>(
  {} as MobileMenuContextType
);

export const MobileMenuProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [menuOpenState, setMenuOpenState] = useState(false);
  const toggleMenu = () => setMenuOpenState(!menuOpenState);
  const stateChangeHandler = (newState: any) =>
    setMenuOpenState(newState.isOpen);

  return (
    <MobileMenuContext.Provider
      value={{
        isMenuOpen: menuOpenState,
        toggleMenu,
        stateChangeHandler,
      }}
    >
      {children}
    </MobileMenuContext.Provider>
  );
};

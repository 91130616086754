import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
// contexts
import { MobileMenuContext } from "src/providers/MobileMenuProvider";
import { ThemeContext } from "./providers/ThemeProvider";

import "src/styles/App.scss";
// components
import Main from "src/components/Main";
import MobileMenu from "src/components/shared/MobileMenu";
import PageNotFound from "src/errors/404";

const App = () => {
  const { isMenuOpen, stateChangeHandler } = useContext(MobileMenuContext);
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <div
              className={"App"}
              style={{ cursor: "url('images/cursor/cat-pow.png'),auto" }}
            >
              <Main />
              {
                <MobileMenu
                  isOpen={isMenuOpen}
                  onStateChange={stateChangeHandler}
                />
              }
            </div>
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
};

export default App;

import React from "react";

const Pow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="199"
    height="199"
    viewBox="0 0 199 199"
    fill="none"
  >
    <g clip-path="url(#clip0_197_1141)" filter="url(#filter0_d_197_1141)">
      <path
        d="M93.8921 35.6832C85.9462 36.4007 79.2486 42.0869 76.2466 50.664C71.625 63.8076 78.5512 76.456 90.2779 76.1192C105.626 75.7665 115.911 55.5097 107.406 42.5167C104.651 38.384 98.465 35.1697 93.8921 35.6832Z"
        fill="#744D87"
      />
      <path
        d="M134.451 53.0685C127.13 53.4774 120.986 58.2498 117.547 66.3117C115.827 70.3427 115.315 72.5064 115.38 75.8201C115.458 81.0287 118.217 87.4632 121.466 90.2458C128.092 95.7016 138.388 94.0972 145.271 86.4364C148.163 83.3163 150.85 76.2478 151.09 71.2569C151.251 66.6431 148.838 59.7814 145.967 57.0776C143.482 54.6212 138.231 52.8737 134.451 53.0685Z"
        fill="#744D87"
      />
      <path
        d="M53.438 57.5275C49.4794 57.5638 46.6041 58.7195 42.7003 61.9008C39.926 64.1674 38.9558 65.4787 36.9693 70.1351C33.5003 78.2665 33.95 86.2625 38.2257 92.0297C41.6563 96.6971 45.4992 98.6651 51.0053 98.4676C58.4758 98.2866 64.2033 93.9117 67.6723 85.7803C70.1332 80.0118 70.4425 75.0506 68.5881 69.577C66.1054 62.1102 60.2738 57.4865 53.438 57.5275Z"
        fill="#744D87"
      />
      <path
        d="M146.558 97.6649C133.166 98.441 123.015 115.88 128.681 128.401C134.118 140.497 148.574 141.078 157.902 129.613C164.585 121.457 164.931 108.707 158.65 102.248C155.937 99.3658 150.745 97.4794 146.558 97.6649Z"
        fill="#744D87"
      />
      <path
        d="M95.1943 85.1986C89.5594 85.5055 85.0987 87.8742 77.7479 94.5139C71.1597 100.329 68.3742 101.851 63.4538 102.217C58.6028 102.612 52.2777 105.499 48.0079 109.346C44.9269 112.139 43.967 113.619 41.9804 118.275C39.1044 125.017 38.973 129.561 41.4436 135.708C44.0737 142.252 47.0548 145.085 54.9082 148.435C61.0936 151.074 61.9174 151.261 68.1278 150.953C71.7092 150.838 75.3407 150.991 76.2442 151.376C77.1477 151.762 79.7711 154.278 82.137 157.012C86.2422 161.639 86.9473 162.104 93.1328 164.742C98.6927 167.114 100.41 167.518 103.882 167.275C123.455 166.26 132.941 142.869 120.304 126.47C117.212 122.358 115.994 117.895 116.06 110.037C116.119 97.5759 112.298 90.3593 103.541 86.6235C100.691 85.4078 98.3986 85.0048 95.1943 85.1986Z"
        fill="#744D87"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_197_1141"
        x="-7.48529"
        y="0"
        width="213.269"
        height="213.269"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="7.48529" />
        <feGaussianBlur stdDeviation="3.74265" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_197_1141"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_197_1141"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_197_1141">
        <rect
          width="151.119"
          height="151.119"
          fill="white"
          transform="translate(59.2998) rotate(23.1041)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Pow;

import React from "react";
import { Fade, Slide, Zoom } from "react-awesome-reveal";
// style
import classes from "src/styles/components/home/home.module.scss";
// components
import Button from "src/components/shared/Button";
// icons
import Pow from "src/components/shared/icons/Pow";
import { isMobile } from "react-device-detect";

const Home = () => (
  <div className={classes.wrapper} id="home">
    <Fade triggerOnce delay={200}>
      <div className={classes.info}>
        <p className={classes.intro}>
          Hi, My name is{" "}
          <span>
            <Pow />
          </span>
        </p>
        <h1>Sarah Shakiba</h1>
        {isMobile && (
          <Fade delay={300}>
            <div className={classes.image}>
              <img src="images/me-with-frame.png" />
            </div>
          </Fade>
        )}
        <p className={classes.description}>
          I'm a Software Engineer with 5years of experience, ranging from pure
          JS applications to complex applications created with ReactJS and
          Redux, Incluidng Sub-apps. Working on projects that have varied
          requirements and differents challenges is one of my favorites aspects
          of my job.
        </p>
        <a className={classes.hire_me} href="#contact">
          <Button title="Hire me" onClick={() => console.log("")} />
        </a>
      </div>
    </Fade>
    {!isMobile && (
      <Slide direction="right" duration={500} triggerOnce>
        <div className={classes.image}>
          <img src="images/me-with-frame.png" />
        </div>
      </Slide>
    )}
  </div>
);

export default Home;

import React from "react";
import { slide as Menu } from "react-burger-menu";
// types
import { MobileMenuType } from "src/core/types";
// styles
import classes from "src/styles/components/shared/mobileMenu.module.scss";
import Pow from "src/components/shared/icons/Pow";

const MobileMenu = ({ isOpen, onStateChange }: MobileMenuType) => {
  return (
    <Menu
      isOpen={isOpen}
      right
      customBurgerIcon={false}
      onStateChange={onStateChange}
      styles={{
        bmOverlay: {
          top: "0",
          background: "rgba(48, 46, 46, 0.52)",
        },
        bmMenuWrap: {
          top: "0",
          width: "50%",
          backgroundImage:
            "linear-gradient(180deg, rgba(209, 204, 213, 0.12) 0%, rgba(255, 255, 255, 0.51) 100%)",
          border: "2px solid white",
          borderRadius: "16px 0 0 16px",
        },
        bmBurgerButton: {
          display: "none",
        },
        bmCrossButton: {
          display: "none",
        },
      }}
    >
      <div className={classes.wrapper}>
        <div className={classes.pows}>
          <div className={classes.pow1}>
            <Pow />
          </div>
          <div className={classes.little_pows}>
            <div className={classes.pow2}>
              <Pow />
            </div>
            <div className={classes.pow3}>
              <Pow />
            </div>
          </div>
        </div>
        <nav>
          <a href="#home" className={classes.menu_item}>
            Home
          </a>
          <a href="#about" className={classes.menu_item}>
            About
          </a>
          <a href="#experience" className={classes.menu_item}>
            Experience
          </a>
          <a href="#contact" className={classes.menu_item}>
            Contact
          </a>
        </nav>
        <div className={classes.pows_menu_footer}>
          <img src="/images/cat-pows-mobile-menu.png" />
        </div>
      </div>
    </Menu>
  );
};

export default MobileMenu;

import React from "react";
// types
import { ButtonType } from "src/core/types";
// styles
import classes from "src/styles/components/shared/button.module.scss";

const Button = ({ title, onClick }: ButtonType) => (
  <button className={classes.button} onClick={onClick}>
    {title}
  </button>
);

export default Button;

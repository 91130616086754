import React, { useContext } from "react";
import { Slide } from "react-awesome-reveal";
// contexts
import { ThemeContext } from "src/providers/ThemeProvider";
// componenst
import ThemeSwitch from "src/components/shared/ThemeSwitch";
import Button from "src/components/shared/Button";
import Pow from "src/components/shared/icons/Pow";
// styles
import classes from "src/styles/components/shared/header.module.scss";
// hooks
import { useScrollDirection } from "src/core/hooks/useScrollDirection";
// constants
import { scrollDirctions, themes } from "src/core/constants";

const DesktopHeader = () => {
  const { theme } = useContext(ThemeContext);
  const scrollDirection = useScrollDirection();
  return (
    <header
      className={`${classes.header} ${
        scrollDirection === scrollDirctions.DOWN && classes.hide
      } ${theme === themes.LIGHT_MODE ? classes.light : classes.dark}`}
    >
      <ThemeSwitch />
      <nav className={classes.navbar}>
        <Slide direction="down" delay={50}>
          <a href="#home">Home</a>
        </Slide>
        <Slide direction="down" delay={100}>
          <a href="#about">About</a>
        </Slide>
        <Slide direction="down" delay={150}>
          <a href="#experience">Experience</a>
        </Slide>
      </nav>
      <Slide direction="down" delay={200}>
        <div className={classes.intouch}>
          <a href="#contact">
            <Button
              title="Get in Touch"
              onClick={() => {
                console.log("");
              }}
            />
          </a>
          <div className={classes.pows}>
            <div className={classes.pow1}>
              <Pow />
            </div>
            <div className={classes.pow2}>
              <Pow />
            </div>
          </div>
        </div>
      </Slide>
    </header>
  );
};

export default DesktopHeader;

import React, { useContext } from "react";
// styles
import classes from "src/styles/components/shared/themeSwitch.module.scss";
// constants
import { themes } from "src/core/constants";
// contexts
import { ThemeContext } from "src/providers/ThemeProvider";

const ThemeSwitch = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);
  return (
    <>
      <div className={classes.checkbox_wrapper_5}>
        <div className={classes.check}>
          <input
            id="check-5"
            type="checkbox"
            checked={theme === themes.LIGHT_MODE}
            onChange={toggleTheme}
          />
          <label htmlFor="check-5"></label>
        </div>
      </div>
    </>
  );
};

export default ThemeSwitch;

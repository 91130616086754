import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "src/styles/index.scss";
import App from "src/App";
// providers
import { ThemeProvider } from "src/providers/ThemeProvider";
import { MobileMenuProvider } from "src/providers/MobileMenuProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <ThemeProvider>
      <MobileMenuProvider>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </MobileMenuProvider>
    </ThemeProvider>
  </BrowserRouter>
);

import React from "react";
//styles
import classes from "src/styles/errors/error.module.scss";

const PageNotFound = () => (
  <div className={classes.wrapper}>
    <p>404 | Page Not Found</p>
    <div className={classes.image_wrapper}>
      <img src="images/cats/lazy-cat.gif" />
    </div>
  </div>
);

export default PageNotFound;

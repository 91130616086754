import React, { useContext } from "react";
// contexts
import { ThemeContext } from "src/providers/ThemeProvider";
import { MobileMenuContext } from "src/providers/MobileMenuProvider";
// componenst
import ThemeSwitch from "src/components/shared/ThemeSwitch";
import Button from "src/components/shared/Button";
import MenuIcon from "src/components/shared/icons/MenuIcon";
// styles
import classes from "src/styles/components/shared/header.module.scss";
// hooks
import { useScrollDirection } from "src/core/hooks/useScrollDirection";
// constants
import { scrollDirctions, themes } from "src/core/constants";

const MobileHeader = () => {
  const { toggleMenu } = useContext(MobileMenuContext);
  const { theme } = useContext(ThemeContext);
  const scrollDirection = useScrollDirection();
  return (
    <header
      className={`${classes.header} ${
        scrollDirection === scrollDirctions.DOWN && classes.hide
      } ${theme === themes.LIGHT_MODE ? classes.light : classes.dark}`}
    >
      <ThemeSwitch />
      <div className={classes.intouch}>
        <a href="#contact">
          <Button
            title="Get in Touch"
            onClick={() => {
              console.log("");
            }}
          />
        </a>
        <div className={classes.menu} onClick={toggleMenu}>
          <MenuIcon />
        </div>
      </div>
    </header>
  );
};

export default MobileHeader;

import React, { useContext } from "react";
import { Fade, Slide } from "react-awesome-reveal";
// constants
import { themes } from "src/core/constants";
// context
import { ThemeContext } from "src/providers/ThemeProvider";
// components
import Pow from "src/components/shared/icons/Pow";
// styles
import classes from "src/styles/components/about/about.module.scss";
import { isMobile } from "react-device-detect";

const About = () => {
  const { theme } = useContext(ThemeContext);
  return (
    <div
      className={`${classes.wrapper} ${
        theme === themes.LIGHT_MODE ? classes.light : classes.dark
      }`}
      id="about"
    >
      <Fade triggerOnce delay={300}>
        <div className={classes.title}>
          <img className={classes.title_image} src="images/cats/about-me.gif" />
          <h1>About Me</h1>
          <Pow />
        </div>
      </Fade>
      <Slide triggerOnce duration={500} direction="right" delay={200}>
        <div className={classes.section}>
          <div className={classes.pow1}>
            <Pow />
          </div>
          When I was in school, I couldn't decide whitch subject i loved more:
          mathematics or art? Years later, after entering the university to
          study computer engineering, I found an answar that could meet both of
          them: Frontend Development! A Front-End Developer must mix art and
          math to optimize the view while making it appear presious at the same
          time.
        </div>
      </Slide>
      <Slide triggerOnce duration={500} direction="left" delay={200}>
        <div className={classes.section}>
          <div className={classes.pow2}>
            <Pow />
          </div>
          Currently, I am a software engineer with 5years of experience in
          Frontend Development, specializing in React, Redux and RTX Query
          (modern Redux), with a recent insterest in Svelte. As of now, I work
          at Porsline, an online survey tool that has six hundred thousand users
          and seventy thousand responses per day.
        </div>
      </Slide>
      <Slide triggerOnce duration={500} direction="right" delay={200}>
        <div className={classes.section}>
          <div className={classes.pow3}>
            <Pow />
          </div>
          I'm always eager to learn and share what i know with others! Since
          frameworks and technologies are growing rapidly, I try to stay updated
          for better and more optimized ones.
        </div>
      </Slide>
      <Slide triggerOnce duration={500} direction="left" delay={200}>
        <div className={classes.education}>
          <div className={classes.title}>
            <h1>Education</h1>
            <div className={classes.education_image}>
              <img src="images/cats/graduated-cat.gif" />
            </div>
          </div>
          <div className={classes.separator} />
          <div className={classes.info}>
            <div className={classes.texts}>
              <p className={classes.uni}>K.N.Toosi University of Technology</p>
              <p className={classes.deg}>
                Bachelor Degree of Computer Engineering
              </p>
              <p className={classes.period}>2015-2019</p>
              <p className={classes.gpa}>GPA: 16.18/20</p>
            </div>
            {!isMobile && (
              <div className={classes.pows}>
                <div className={classes.pow4}>
                  <Pow />
                </div>
                <div className={classes.pow5}>
                  <Pow />
                </div>
              </div>
            )}
          </div>
        </div>
      </Slide>
    </div>
  );
};

export default About;

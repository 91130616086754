import React, { useContext } from "react";
import { isMobile } from "react-device-detect";
// components
import DesktopHeader from "src/components/shared/DesktopHeader";
import MobileHeader from "src/components/shared/MobileHeader";
import Home from "src/components/home/Home";
import About from "src/components/about/About";
import Experience from "src/components/experience/Experience";
import Contact from "src/components/contact/Contact";
// hooks
import { useMousePosition } from "src/core/hooks/useMousePosition";
// styles
import classes from "src/styles/components/main.module.scss";
import { ThemeContext } from "src/providers/ThemeProvider";
import { themes } from "src/core/constants";

const Main = () => {
  const { theme } = useContext(ThemeContext);
  const mousePosition = useMousePosition();
  return (
    <div
      className={`${classes.container} ${
        theme === themes.LIGHT_MODE ? classes.light_bg : classes.dark_bg
      }`}
      style={{ cursor: "url('images/cursor/cat-pow.png'),auto" }}
    >
      {/* <img
        src="images/cursor/cat-footprint.gif
          "
        style={{
          position: "absolute",
          top: Number(mousePosition.y),
          left: Number(mousePosition.x) - 70,
          zIndex: 1000000,
        }}
      /> */}

      {isMobile ? <MobileHeader /> : <DesktopHeader />}
      <div className={`${classes.view}`}>
        <Home />
      </div>
      <div className={`${classes.view}`}>
        <About />
      </div>
      <div className={`${classes.view}`}>
        <Experience />
      </div>
      <div className={`${classes.view}`}>
        <Contact />
      </div>
      <div className={classes.footer}></div>
    </div>
  );
};

export default Main;

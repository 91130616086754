import React, { useState } from "react";
// constants
import { themes } from "src/core/constants";
// types
import { ThemeContextType, ThemeType } from "src/core/types";

export const ThemeContext = React.createContext<ThemeContextType>(
  {} as ThemeContextType
);

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [theme, setTheme] = useState<ThemeType>(themes.LIGHT_MODE as ThemeType);
  const toggleTheme = () => {
    setTheme(
      theme === themes.LIGHT_MODE
        ? (themes.DARK_MODE as ThemeType)
        : (themes.LIGHT_MODE as ThemeType)
    );
  };

  const lightBackground = "#D3CEDF";
  const darkBackgrongd = "#241D2B";
  const backgroundColor =
    theme === themes.LIGHT_MODE ? lightBackground : darkBackgrongd;

  document.body.style.backgroundColor = backgroundColor;
  if (theme === themes.LIGHT_MODE) {
    document.body.classList.add("light");
    document.body.classList.remove("dark");
  } else {
    document.body.classList.add("dark");
    document.body.classList.remove("light");
  }

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

import React, { useContext } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
// constants
import { themes } from "src/core/constants";
// context
import { ThemeContext } from "src/providers/ThemeProvider";
// components
import Pow from "src/components/shared/icons/Pow";
// styles
import classes from "src/styles/components/experience/experience.module.scss";
import { isMobile } from "react-device-detect";

const Experience = () => {
  const { theme } = useContext(ThemeContext);
  return (
    <div
      className={`${classes.wrapper} ${
        theme === themes.LIGHT_MODE ? classes.light : classes.dark
      }`}
      id="experience"
    >
      <Fade>
        <div className={classes.title}>
          <div className={classes.pow1}>
            <Pow />
          </div>
          <h1>My Work Experience</h1>
          <div className={classes.pow2}>
            <Pow />
          </div>
        </div>
      </Fade>
      <Zoom
        duration={500}
        triggerOnce
        className={classes.section_animation_wrapper}
      >
        <div className={classes.section}>
          <div className={classes.porsline}>
            <div className={classes.info}>
              <div className={classes.texts}>
                <h3>Porsline</h3>
                <h4>Frontend Developer</h4>
                <h5>Oct2020 - Now</h5>
              </div>
              {isMobile && <img src="images/cats/cat-on-laptop.gif" />}
            </div>
            <ul>
              <li>
                Refactoring main applications using React.Js, Redux, and
                RTX-Querry (modern Redux)
              </li>
              <li>
                Optimizing performance by using different solutions and
                libraries
              </li>
              <li>
                Implementing a variety of features, including integrations with
                other services
              </li>
              <li>Perfoeming code reviews for other teammates</li>
              <li>Using storybook for UI simulation</li>
              <li>
                Taking part in tech-talks and looking for up-to-date tools and
                best practices
              </li>
            </ul>
            <h4 className={classes.stack}>
              Next.Js | React.Js | Redux | Redux-Saga | RTX-Querry | Typescript
              | SASS
            </h4>
          </div>
          {!isMobile && <img src="images/cats/cat-on-laptop.gif" />}
        </div>
      </Zoom>
      <Zoom
        duration={500}
        delay={100}
        triggerOnce
        className={classes.section_animation_wrapper}
      >
        <div className={classes.section}>
          <div className={classes.images}>
            {!isMobile && (
              <div className={classes.pows}>
                <div className={classes.pow3}>
                  <Pow />
                </div>
                <div className={classes.pow4}>
                  <Pow />
                </div>
              </div>
            )}
            {!isMobile && <img src="images/cats/cat-with-phone.gif" />}
          </div>
          <div className={classes.mobtaker}>
            <div className={classes.texts}>
              <div className={classes.info}>
                <div className={classes.texts_header}>
                  <h3>Mobtaker Darya</h3>
                  <h4>React Native Developer</h4>
                  <h5>Aug2020 - Oct2020</h5>
                </div>
                {isMobile && <img src="images/cats/cat-with-phone.gif" />}
              </div>
              <ul>
                <li>Implementing a React native application</li>
                <li>Offline mode management</li>
                <li>Implementing UX using Adobe XD</li>
              </ul>
              <h4 className={classes.stack}>React Native | Nude React.Js</h4>
            </div>
            {!isMobile && (
              <div className={classes.pow5}>
                <Pow />
              </div>
            )}
          </div>
        </div>
      </Zoom>
      <Zoom
        duration={500}
        delay={100}
        triggerOnce
        className={classes.section_animation_wrapper}
      >
        <div className={classes.section}>
          <div className={classes.tis}>
            <div className={classes.info}>
              <div className={classes.texts}>
                <h3>T.I.S</h3>
                <h4>Frontend Developer</h4>
                <h5>Oct2018 - Aug2020</h5>
              </div>
              {isMobile && <img src="images/cats/cat-on-screen.gif" />}
            </div>
            {!isMobile && (
              <div className={classes.pow6}>
                <Pow />
              </div>
            )}
            <ul>
              <li>
                Implementing a document organization web application using
                React.js and React-admin
              </li>
              <li>
                Developing features for the lagecy application for Iran ports
                fund management using Process360 (a BPMS for workflow
                management)
              </li>
              <li>
                Using JavaScript ES6 and Processmaker (another business process
                management system) to develop a newer version of the fund
                management application
              </li>
              <li>
                Web application maintenance for Razi serum and vaccine research
                institute
              </li>
            </ul>
            <h4 className={classes.stack}>
              React.Js | React-admin | TypeScript | Javascript ES6 | Node.js |
              GraphQL | Processmaker | Process360
            </h4>
          </div>
          {!isMobile && <img src="images/cats/cat-on-screen.gif" />}
        </div>
      </Zoom>
    </div>
  );
};

export default Experience;

import React, { useContext } from "react";
import { Bounce } from "react-awesome-reveal";
// contexts
import { ThemeContext } from "src/providers/ThemeProvider";
// components
import Button from "src/components/shared/Button";
import Pow from "src/components/shared/icons/Pow";
// styles
import classes from "src/styles/components/contact/contact.module.scss";
// constants
import { themes } from "src/core/constants";
// pdf resume
import MyCV from "src/components/contact/Sarah-Shakiba-resume.pdf";

const Contact = () => {
  const { theme } = useContext(ThemeContext);
  return (
    <Bounce triggerOnce delay={200}>
      <div className={classes.wrapper} id="contact">
        <div className={classes.title}>
          <div className={classes.title_image}>
            <img src="images/cats/curios-cat.gif" />
          </div>
          <h1>Contact Info</h1>
          <div className={classes.pows}>
            <div className={classes.pow1}>
              <Pow />
            </div>
            <div className={classes.pow2}>
              <Pow />
            </div>
          </div>
        </div>
        <div className={classes.contacts}>
          <a
            className={classes.social}
            href="https://www.linkedin.com/in/sara-shakiba-720297153/"
            target="_blank"
            rel="noreferrer"
          >
            <img src="images/socials/linkedin-icon.png" />
          </a>
          <a
            className={classes.social}
            href="https://github.com/SarahShkb"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={`images/socials/github-icon-${
                theme === themes.LIGHT_MODE ? "light" : "dark"
              }.png`}
            />
          </a>
          <a
            className={classes.social}
            href="mailto:sarah.shkb@gmail.com"
            target="_blank"
          >
            <img src="images/socials/gmail-icon.webp" />
          </a>
          <a
            className={classes.social}
            href="https://t.me/Sarahshkb/"
            target="_blank"
            rel="noreferrer"
          >
            <img src="images/socials/telegram-icon.webp" />
          </a>
        </div>
        <a
          href={MyCV}
          download="Sarah-Shakiba-resume"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button title="Download My CV" onClick={() => console.log("")} />
        </a>
      </div>
    </Bounce>
  );
};

export default Contact;
